:root {
  --loader-blue: #287bff;
  --black-fang: rgba(0, 0, 0, 0.25);
  --white-fang: #fff;
  --google-blue: #4285f4;
  --facebook-blue: #3b5998;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.fangbtn {
  display: flex;
  align-items: center;
  width: 30rem;
  height: 4.5rem;
  overflow: hidden;
  padding: 0.2rem;
}
.fangbtn--google {
  background-color: var(--google-blue);
  color: var(--white-fang);
  font-weight: 500;
}
.fangbtn--meta {
  background-color: var(--facebook-blue);
  color: var(--white-fang);
  font-weight: 500;
}
.fangbtn__iconcont {
  /* max-width: 2rem; */
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--white-fang);
}
.fangbtn_icon {
  width: 80%;
}
.fangbtn__textcont {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  flex-grow: 1;
}
.fangbtn__text {
  font-size: 1.4rem;
}
.fangcont {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 5rem;
}
.signup__formonthirdparty {
  display: flex;
  align-items: center;
}
