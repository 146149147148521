:root {
  font-family: "Kanit", sans-serif;
  --side-icon-hover: #ffffff;
  --side-icon-shadow: rgba(216, 210, 252, 0.64);
  --home-button: rgba(216, 210, 252, 0.64);
  --sidenav-background: #e5e5e5;
  --topbar-logos: rgba(216, 210, 252, 0.64);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.topbar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.topbar__imgcont {
  width: 18rem;
  height: 7rem;
}
.topbar__logo {
  height: 4.8rem;
  width: 4.8rem;
  background-color: var(--side-icon-hover);
  border-radius: 2rem;
  box-shadow: 0 0.4rem 1.5rem var(--topbar-logos);
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbar__logoimg {
  height: 2.6rem;
  width: 2.6rem;
}
.topbar__logos {
  display: flex;
  gap: 1.2rem;
  margin-right: 4.3rem;
}
